<template>
  <a-menu v-model="current" mode="horizontal" @click="handleClick">
    <a-menu-item key="/searchWords">搜索下拉词推荐</a-menu-item>
    <a-menu-item key="/screenshotPC">PC品专截图</a-menu-item>
    <a-menu-item key="/screenshotMobile">Mobile品专截图</a-menu-item>
  </a-menu>
</template>

<script>
export default {
  name: 'HeaderTab',
  computed: {
    current: {
      get: function() {
        return ['/' + this.$route.path.split('/')[1]]
      },
      set: function() {

      }
    }
  },
  methods: {
    handleClick({ key }) {
      if (key === '/batch') {
        this.$store.state.batch.activedTab = 'group' // 解决vuex activedTab在切换headerTab不更新的问题
      }
      this.$router.push(key)
    }
  }
}
</script>

<style lang='less'>
  @import '../../styles/common/variables.less';
  @import '../../styles/common/mixins.less';

  .ant-menu {
    &.ant-menu-submenu-content {
      .ant-menu-item {
        color: #1a1a1a;
      }
    }
    height: 100%;
    width: 100%;
    background-color: @1a;
    color: @hc;
    border-bottom: none;
    .ant-menu-item {
      padding: 0;
      text-align: center;
      border-bottom: none;
      height: 64px;
      width: 94px;
      line-height: 64px;
      margin-right: 24px;
    }
    .ant-menu-submenu {
      text-align: center;
      border-bottom: none;
      height: 64px;
      width: 94px;
      line-height: 64px;
      .ant-menu-submenu-title {
        padding: 0;
      }
    }
  }
</style>
