<template>
  <div class="ag-header">
    <router-link class="logo-wrap" to="/searchWords" tag="div">
      <img src="../../../assets/logo-1.png" class="logo">
      <span class="text">小工具</span>
    </router-link>

    <div class="tab-wrap">
      <HeaderTab/>
    </div>
    <div class="user-wrap">
      <div class="name">
        <i class="user-icon"/>
       
      </div>
      <a-dropdown style="margin-left:12px">
        <a href="#" style="color:white">
          {{ loginUser.name }}
          <a-icon type="down"/>
        </a>
        <a-menu slot="overlay" @click="handlerClick">
          <a-menu-item key="/login">
            <span>退出</span>
          </a-menu-item>
        </a-menu>
      </a-dropdown>
    </div>
  </div>
</template>

<script>
import HeaderTab from '../../tab/headerTab'
import { authorityMixin } from '../../../utils/mixin'
import { hasLoginUserCookies } from '../../../../../utils/util'
import storage from 'good-storage'
import Cookies from 'js-cookie'

export default {
  name: 'AdHeader',
  mixins: [authorityMixin],
  components: {
    HeaderTab
  },
  methods: {
    hasLoginUserCookies,
    handlerClick ({ key }) {
      let position = key || arguments[0]

        this.$router.push(position)
      
    }
  }
}
</script>

<style scoped lang='less'>
  @import '../../../styles/common/variables.less';
  @import '../../../styles/common/mixins.less';

  .ag-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 64px;
    background-color: @1a;
    font-size: 14px;
    font-family: @pr;
    .logo-wrap,
    .tab-wrap,
    .user-wrap {
      display: flex;
      height: 100%;
    }
    .logo-wrap {
      display: flex;
      width: 250px;
      align-items: center;
      padding-left: 24px;
      .logo {
        box-sizing: border-box;
        width: 48px;
        margin-right: 16px;
        transform: translate(0, -2px);
      }
      .text {
        color: @w;
        padding-left: 16px;
        border-left: 1px solid rgba(255, 255, 255, 0.5);
      }
    }
    .tab-wrap {
      padding-left: 20px;
      flex: 1;
      height: 100%;
      background-color: @1a;
    }
    .user-wrap {
      display: flex;
      align-items: center;
      padding-right: 25px;
      float: right;
      color: @w;
      .name {
        cursor: pointer;
        display: flex;
        .user-icon {
          .backgroundImg('../../assets/user.png');
          width: 24px;
          height: 24px;
        }
        .text {
          margin-left: 8px;
          margin-right: 9px;
          line-height: 24px;
          height: 24px;
        }
        .anticon-down {
          transform: translate(0, 5px);
        }
      }
      .user-menu {
        width: 120px;
        color: #fff;
        .ant-menu-submenu {
          width: 100%;
        }
        .ant-menu-submenu-title {
          width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }
</style>
