import { mapState } from 'vuex'

export const autoCalcMixin = {
  mounted() {
    this.$refs.autoCalculateBoundary.style.minHeight =
      document.body.clientHeight - 111 + 'px' // 让白色背景填充
  }
}

export const authorityMixin = {
  computed: {
    ...mapState({
      loginUser: state => state.auth.loginUser,
      token: state => state.auth.token
    })
  }
}
