<template>
  <div>
    <AgHeader />
    <router-view />
    <div v-if="this.$store.state.loading" class="loading-wrap">
      <a-spin size="large" class="loading" tip="数据加载中..." />
    </div>
  <!--<LoadModal></LoadModal>-->
  </div>
</template>
<script>
import AgHeader from './agHeader/index'
// import loadModal from 'components/loadModal'
export default {
  name: 'Layout',
  components: {
    AgHeader: AgHeader
    // LoadModal: loadModal
  }
}
</script>

<style scoped lang='less'>
  .loading-wrap{
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: rgba(0,0,0,0.05);
    display: flex;
    .loading{
      margin: auto;
    }
  }
</style>
